import React, {useEffect} from "react";
import {style} from "./style";
import email from "../../../../../assets/png/mail.png"
import linkedin from "../../../../../assets/png/linkedin.png"
import stonks from "../../../../../assets/png/stonks.png"
import stats from "../../../../../assets/png/stats.png"
import {AuthorsResults} from "../../type";
import AddToFilters from "../AddToFilters/AddToFilters";
import numberWithPoints from "../../../../../common/numberWithDots";

type Props = {
    result: AuthorsResults,
    filterClick?: () => void
}

export default function AuthorResult({result, filterClick}: Props) {
    const [abbreviation, setAbbreviation] = React.useState<string>("")
    const [sources, setSources] = React.useState<string>("")

    useEffect(() => {
        let words = result.author.name.split(' ')
        let auxAbbreviation = ""
        auxAbbreviation += words[0].substring(0, 1)
        if (words.length > 1)
            auxAbbreviation += words[words.length - 1].substring(0, 1)
        setAbbreviation(auxAbbreviation)
        let auxSources = ""
        for (let i = 0; i <= result.sources.length - 1; i++) {
            if (i === 0) {
                auxSources += `${result.sources[i].name} `
            }
            if (i === 1) {
                if (result.sources.length > 2)
                    if (result.sources.length === 3)
                        auxSources += `${result.sources[i].name} e ${result.sources[i + 1].name}`
                    else
                        auxSources += `${result.sources[i].name} e ${result.sources.length - 2} altri`
                auxSources += `e ${result.sources[i].name}`
            }
        }
        setSources(auxSources)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        // <div style={style.box}>
        //     <div>
        //         <div className={"d-flex"} style={{alignItems: "center"}}>
        //             <div style={style.nameImage}>{abbreviation.toUpperCase()}</div>
        //             <div className={"column"}>
        //                 <div className={"d-flex flex-row"} style={{alignItems: "center"}}>
        //                     {result.author.name !== null ?
        //                         <h5 style={style.resultTitle}>{result.author.name}</h5> : null}
        //                     {filterClick ? <AddToFilters onClick={filterClick}/> : null}
        //                 </div>
        //                 <div style={style.resultSubtitle}>{`attivo / a in ${sources}`}</div>
        //             </div>
        //         </div>
        //         <div style={style.resultInfoTextLeft}>
        //             <div className={"verticalCenter"} style={{marginBottom: "5px"}}>
        //                 <img style={style.img} src={stonks} alt={""}/>
        //                 <span>{` x articoli dell'autore analizzati in totale.`}</span>
        //                 {/*${result.numberPublicationsTotal}*/}
        //             </div>
        //             <div className={"verticalCenter"}>
        //                 <img style={style.img} src={stats} alt={""}/>
        //                 <span>
        //                     {`${numberWithPoints(result.publications.number)} articoli ${result.publications.period.substring(0, 6) === "Ultimi" ? "negli " : "nell'"}${result.publications.period.toLowerCase()}`}
        //                 </span>
        //             </div>
        //         </div>
        //     </div>
        //     <div style={style.resultInfoTextRight}>
        //         {/*{result.emails !== null && result.emails.length > 0 ?*/}
        //         {/*    <>*/}
        //         {/*        <div className={"verticalCenter"} style={{marginBottom: "8px"}}>*/}
        //         {/*            <a href={`mailto:${result.emails[0]}`}*/}
        //         {/*               style={{color: colors.supportDarker}}>{result.emails[0]}</a>*/}
        //         {/*            <img style={style.img} src={email} alt={""}/>*/}
        //         {/*        </div>*/}
        //         {/*        {result.emails.slice(1).map((email, index) => {*/}
        //         {/*            return (*/}
        //         {/*                <div key={`email-${index}`} className={"verticalCenter"} style={{marginBottom: "8px"}}>*/}
        //         {/*                    <a href={`mailto:${email}`} style={{color: colors.supportDarker}}>{email}</a>*/}
        //         {/*                    <div style={style.img}/>*/}
        //         {/*                </div>)*/}
        //         {/*        })}*/}
        //         {/*    </> : null}*/}
        //         {result.linkedin.name !== null ? <div className={"verticalCenter"} style={{marginBottom: "8px"}}>
        //             <a href={`https://www.linkedin.com/in/${result.linkedin.name}/`}
        //                style={{color: colors.supportDarker}}>{result.linkedin.name}</a>
        //             <img style={style.img} src={linkedin} alt={""}/>
        //         </div> : null}
        //         {result.twitter.name !== null ? <div className={"verticalCenter"} style={{marginBottom: "8px"}}>
        //             <a href={`https://twitter.com/${result.twitter.name.replace("@", "")}/`}
        //                style={{color: colors.supportDarker}}>{result.twitter.name}</a>
        //             <img style={style.img} src={twitter} alt={""}/>
        //         </div> : null}
        //     </div>
        // </div>
        <div style={style.box}>
            <div className={"row  w-100"}>
                <div className={"col-md-12 col-xl-7"}>
                    <div className={"row"}>
                        <div className={"col-xl-12 col-xxl-6 d-flex justify-content-center flex-column"}>
                            <div className={"row"}>
                                <div style={style.nameImage}>{abbreviation.toUpperCase()}</div>
                                <div style={{width: "calc(100% - 50px)", padding: 0}}>
                                    <div className={"d-flex flex-row"} style={{alignItems: "center"}}>
                                        {result.author.name !== null ?
                                            <h5 style={style.resultTitle}>{result.author.name}</h5> : null}
                                    </div>
                                    <p style={style.resultSubtitle}>{`attivo/a in ${sources}`}</p>
                                    {filterClick ? <AddToFilters onClick={filterClick}/> : null}
                                </div>
                            </div>

                        </div>
                        <div className={"col-xl-12 col-xxl-6 p-0 ml-xl-50 mt-xl-8"}>
                            <div>
                                <div className={"verticalCenter"} style={{marginBottom: "5px"}}>
                                    <img style={style.img} src={stonks} alt={""}/>
                                    <span style={style.text}>
                                        {/*{`${numberWithPoints(result.publications.number)} articoli pubblicati negli ultimi sei mesi`}*/}
                                        {`${numberWithPoints(result.publications.number)} articoli pubblicati in ${result.publications.period.toLowerCase()}`}
                                    </span>
                                </div>
                                <div className={"verticalCenter"}>
                                    <img style={style.img} src={stats} alt={""}/>
                                    <span style={style.text}>
                                        {`${numberWithPoints(result.publicationsInSelection)} articoli rilevanti per la ricerca corrente`}
                                    </span>
                                </div>
                                {result.sources.length > 0 && ![undefined, ""].includes(result.sources[0].role) ?
                                    <div className={"verticalCenter"} style={{...style.flex, marginTop: "5px"}}>
                                        {/*<img style={style.img} src={stats} alt={""}/>*/}
                                        <div style={{...style.img, ...style.flex}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                 height="1.2rem"
                                                 fill={"#3D474A"}>
                                                {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                                                <path
                                                    d="M96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm94.5 200.2l18.6 31L175.8 483.1l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7H162.5c0 0 0 0 .1 0H168 280h5.5c0 0 0 0 .1 0H417.3c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9L238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2H224 204.3c-12.4 0-20.1 13.6-13.7 24.2z"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <span style={style.text}>
                                                {result.sources[0].role}
                                            </span>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-md-12 col-xl-5"}>
                    <div className={"row"}>
                        <div className={"col-xl-12 col-xxl-6"}>
                            {result.emails !== null && result.emails.length > 0 ?
                                <>
                                    <div className={"verticalCenter"} style={{
                                        marginBottom: "8px", overflowWrap: "break-word",
                                    }}>
                                        <img style={style.img} src={email} alt={""}/>
                                        <a href={`mailto:${result.emails[0]}`}
                                           style={style.text} target="_blank" rel={"noreferrer"}>{result.emails[0]}</a>
                                    </div>
                                    {result.emails.slice(1).map((email2, index) => {
                                        return (
                                            <div className={"verticalCenter"} key={`authorRes-${index}`} style={{
                                                marginBottom: "8px", overflowWrap: "break-word",
                                            }}>
                                                <img style={style.img} src={email} alt={""}/>
                                                <a href={`mailto:${email2}`}
                                                   style={style.text} target="_blank" rel={"noreferrer"}>{email2}</a>
                                            </div>)
                                    })}
                                </> : null}
                        </div>
                        <div className={"col-xl-12 col-xxl-6"}>
                            {result.linkedin.name !== null ?
                                <div className={"verticalCenter"} style={{
                                    marginBottom: "8px", overflowWrap: "break-word",
                                }}>
                                    <img style={style.img} src={linkedin} alt={""}/>
                                    <a href={`https://www.linkedin.com/in/${result.linkedin.name}/`}
                                       style={style.text} target="_blank" rel={"noreferrer"}>{result.linkedin.name}</a>
                                </div> : null}
                            {result.twitter.name !== null ?
                                <div className={"verticalCenter"} style={{
                                    marginBottom: "8px", overflowWrap: "break-word",
                                }}>
                                    {/*<img style={style.img} src={twitter} alt={""}/>*/}
                                    {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}
                                    <div style={style.img}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill={"#3d474a"} height="1em"
                                             viewBox="0 0 512 512">
                                            <path
                                                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                        </svg>
                                    </div>
                                    <a href={`https://twitter.com/${result.twitter.name.replace("@", "")}/`}
                                       style={style.text} target="_blank" rel={"noreferrer"}>{result.twitter.name}</a>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {style} from "./style";
import "./style.css"
import AuthorResult from "./results/AuthorResult/AuthorResult";
import ContentResults from "./results/ContentsResult/ContentResults";
import TitlesResults from "./results/TitlesResults/TitlesResults";
import {FiltersWithKeyword} from "../FilterByNew/types";
import ArticleOverlay from "../ArticleOverlay/ArticleOverlay";
import QueryFeedback from "../../QueryFeedback/QueryFeedback";
import {useAppSelector} from "../../../common/Redux/hooks";
import NoResults from "../../NoResults/NoResults";
import {AuthorsResults, ContentsResult, SourcesResults} from "./type";
import FilterByNew from "../FilterByNew/FilterByNew";
import useGetContents from "./Hooks/useGetContent";
import useAssembleFilterPhrase from "./Hooks/useAssembleFilterPhrase";
import useGetAuthors from "./Hooks/useGetAuthors";
import useGetSources from "./Hooks/useGetSources";
import FancyLoader from "../../../common/FancyLoader/FancyLoader";
import {useNavigate} from "react-router-dom";
import DownloadExcel from "../../DownloadCSV/DownloadExcel";
import Loader from "../../../common/Loader/Loader";

export default function Discovery() {
    const [requestId, setRequestId] = React.useState<string>("")
    const [tabIndex, setTabIndex] = React.useState<number>(0)
    const [filters, setFilters] = React.useState<FiltersWithKeyword>({
        thematicAreas: [],
        topic_tree: [],
        mediaTypes: [],
        authors: [],
        geoCategories: [],
        entities: [],
        keywords: [],
        sources: [],
        sourceProfiles: [],
        publicationPeriod: []
    })
    const [pageContents, setPageContents] = React.useState<number>(0)
    const [pageAuthors, setPageAuthors] = React.useState<number>(0)
    const [pageSources, setPageSources] = React.useState<number>(0)
    const [viewContent, setViewContent] = React.useState<boolean>(false)
    const [authorToAdd, setAuthorToAdd] = React.useState<AuthorsResults | undefined>(undefined)
    const [titleToAdd, setTitleToAdd] = React.useState<SourcesResults | undefined>(undefined)
    const [selectedContent, setSelectedContent] = React.useState<ContentsResult | undefined>(undefined)
    const queryCount = useAppSelector((state) => state.queryCounter.value)
    const filterPhrase = useAssembleFilterPhrase(filters)
    const {
        loading: loadingContent,
        hasMore: hasMoreContents,
        contents
    } = useGetContents(pageContents, filters, setRequestId, tabIndex === 0)

    const sortedContents = useMemo(() => {
        const sorted: ContentsResult[][] = []
        let date: string = ""
        for (let i = 0; i < contents.length; i++) {
            if (date === contents[i].publicationDate) {
                sorted[sorted.length - 1].push(contents[i])
            } else {
                sorted.push([contents[i]])
                date = contents[i].publicationDate
            }
        }
        return sorted
    }, [contents])

    const {
        loading: loadingAuthors,
        hasMore: hasMoreAuthors,
        authors
    } = useGetAuthors(pageAuthors, filters, setRequestId, tabIndex === 1)
    const {
        loading: loadingSources,
        hasMore: hasMoreSources,
        sources
    } = useGetSources(pageSources, filters, setRequestId, tabIndex === 2)
    const observerContents = useRef<IntersectionObserver | null>()
    const lastContent = useCallback((node: HTMLDivElement) => {
        if (loadingContent)
            return
        if (observerContents.current)
            observerContents.current.disconnect()
        observerContents.current = new IntersectionObserver(entries => {
            console.log(entries[0].isIntersecting, hasMoreContents, pageContents < 4)
            if (entries[0].isIntersecting && hasMoreContents && pageContents < 4) {
                setPageContents(prevState => prevState + 1)
            }
        })
        if (node) observerContents.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingContent, hasMoreContents])
    const observerAuthors = useRef<IntersectionObserver | null>()
    const lastAuthor = useCallback((node: HTMLDivElement) => {
        if (loadingAuthors)
            return
        if (observerAuthors.current)
            observerAuthors.current.disconnect()
        observerAuthors.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreAuthors && pageAuthors < 2) {
                setPageAuthors(prevState => prevState + 1)
            }
        })
        if (node) observerAuthors.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAuthors, hasMoreAuthors])
    const observerSources = useRef<IntersectionObserver | null>()
    const lastSource = useCallback((node: HTMLDivElement) => {
        if (loadingSources)
            return
        if (observerSources.current)
            observerSources.current.disconnect()
        observerSources.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreSources && pageSources < 2) {
                setPageSources(prevState => prevState + 1)
            }
        })
        if (node) observerSources.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSources, hasMoreSources])
    const userAuth = useAppSelector((state) => state.userAuthorizations.value)
    const navigate = useNavigate();

    const selectContent = (content: ContentsResult) => {
        setViewContent(true)
        setSelectedContent(content)
    }

    const setFiltersFn = (filters: FiltersWithKeyword) => {
        setPageContents(0)
        setPageAuthors(0)
        setPageSources(0)
        setFilters(filters)
    }

    useEffect(() => {
        if (!userAuth.includes(30) && userAuth.length > 0)
            navigate("/smart-targeting")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth])

    return (
        <div style={style.box}>
            {userAuth.includes(30) ?
                <>
                    <FilterByNew setActiveFilters={setFiltersFn}
                                 loading={loadingContent || loadingSources || loadingAuthors}
                                 addTitle={titleToAdd} addAuthor={authorToAdd}/>
                    {viewContent ?
                        <ArticleOverlay discovery={true} post={selectedContent} close={() => setViewContent(false)}/>
                        : null}
                    <div style={viewContent ? style.contentNoScroll : style.content}>
                        <div style={style.header}>
                            <div className={"d-flex justify-content-between align-items-center mb-3"}>
                                <h2 style={style.title}>{"Discovery"}</h2>
                            </div>
                            <p style={style.subtitle}>
                                {"Naviga con l'aiuto dell'AI tra i nostri big data e scopri i giornalisti e i contenuti più rilevanti per la tua ricerca"}
                            </p>
                        </div>
                        <div>
                            <Tabs onSelect={(index) => setTabIndex(index)}>
                                <div className={"d-flex justify-content-between mb-3"}>
                                    <TabList style={style.tabList}>
                                        <Tab
                                            className={`tabListElement ${tabIndex === 0 ? "tabListElementActive" : ""}`}>
                                            {"Contenuti"}
                                        </Tab>
                                        <Tab
                                            className={`tabListElement ${tabIndex === 1 ? "tabListElementActive" : ""}`}>
                                            {"Autori"}
                                        </Tab>
                                        <Tab
                                            className={`tabListElement ${tabIndex === 2 ? "tabListElementActive" : ""}`}>
                                            {"Testate"}
                                        </Tab>
                                    </TabList>
                                    <DownloadExcel
                                        filters={filters}
                                        primary={true}
                                        tabIndex={tabIndex}/>
                                </div>
                                {filterPhrase.length > 0 ?
                                    <p>{`Risultati per: ${filterPhrase}.`}</p> : null}
                                <TabPanel key={"tab-1"}>
                                    {sortedContents && sortedContents.length > 0 ?
                                        sortedContents.map((contentByDate, index) => {
                                            return (
                                                <div key={`contentByDate-${index}`}>
                                                    <h3 style={style.dateSeparator}>{contentByDate[0].publicationDate}</h3>
                                                    {contentByDate.map((content, indexByDate) => {
                                                        if (queryCount > 0 && queryCount % 5 === 0 && index === 1 && indexByDate === 4) {
                                                            return <div key={`content-${index}-${indexByDate}`}
                                                                        ref={sortedContents.length === index + 1 && contentByDate.length === indexByDate + 1 ? lastContent : null}>
                                                                <QueryFeedback subject={"dei contenuti"}
                                                                               requestId={requestId}/>
                                                                <ContentResults result={content} discovery={true}
                                                                                onClick={() => selectContent(content)}/>
                                                            </div>
                                                        }
                                                        return <div key={`content-${index}-${indexByDate}`}
                                                                    ref={sortedContents.length === index + 1 && contentByDate.length === indexByDate + 1 ? lastContent : null}>
                                                            <ContentResults
                                                                result={content} discovery={true}
                                                                onClick={() => selectContent(content)}/>
                                                        </div>
                                                    })}
                                                </div>
                                            )
                                        })
                                        : loadingContent ?
                                            <div style={{
                                                height: "calc(100vh - 400px)",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <FancyLoader/>
                                            </div>
                                            : <NoResults
                                                text={"L'Intelligenza Artificiale di Point-out non ha trovato risultati pertinenti alla tua ricerca. Point-out sta lavorando per ampliare il database e offrirti la massima accuratezza possibile."}/>}
                                    {sortedContents && sortedContents.length > 0 && loadingContent ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <FancyLoader/>
                                        </div>
                                        : null}
                                </TabPanel>
                                <TabPanel key={"tab-2"}>
                                    {authors && authors.length > 0 ? authors.map((content, index) => {
                                            if (queryCount > 0 && queryCount % 5 === 0 && index === 4)
                                                return (
                                                    <div key={`authors-${index}`}
                                                         ref={authors.length === index + 1 ? lastAuthor : null}>
                                                        <QueryFeedback subject={"dei contenuti"} requestId={requestId}/>
                                                        <AuthorResult result={content}
                                                                      filterClick={() => setAuthorToAdd(content)}/>
                                                    </div>)
                                            return (
                                                <div key={`authors-${index}`}
                                                     ref={authors.length === index + 1 ? lastAuthor : null}>
                                                    <AuthorResult result={content}
                                                                  filterClick={() => setAuthorToAdd(content)}/>
                                                </div>)
                                        })
                                        : loadingAuthors ?
                                            <div style={{
                                                height: "calc(100vh - 400px)",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <FancyLoader/></div>
                                            : <NoResults
                                                text={"L'Intelligenza Artificiale di Point-out non ha trovato risultati pertinenti alla tua ricerca. Point-out sta lavorando per ampliare il database e offrirti la massima accuratezza possibile."}/>}
                                    {authors && authors.length > 0 && loadingAuthors ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <FancyLoader/>
                                        </div>
                                        : null}
                                </TabPanel>
                                <TabPanel key={"tab-3"}>
                                    {sources && sources.length > 0 ?
                                        sources.map((content, index) => {
                                            if (queryCount > 0 && queryCount % 5 === 0 && index === 4) {
                                                return (
                                                    <div key={`titles-${index}`}
                                                         ref={sources.length === index + 1 ? lastSource : null}>
                                                        <QueryFeedback subject={"dei contenuti"} requestId={requestId}/>
                                                        <TitlesResults result={content}
                                                                       filterClick={() => setTitleToAdd(content)}/>
                                                    </div>)
                                            }
                                            return (
                                                <div key={`titles-${index}`}
                                                     ref={sources.length === index + 1 ? lastSource : null}>
                                                    <TitlesResults result={content}
                                                                   filterClick={() => setTitleToAdd(content)}/>
                                                </div>)
                                        }) : loadingSources ?
                                            <div style={{
                                                height: "calc(100vh - 400px)",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <FancyLoader/>
                                            </div>
                                            : <NoResults
                                                text={"L'Intelligenza Artificiale di Point-out non ha trovato risultati pertinenti alla tua ricerca. Point-out sta lavorando per ampliare il database e offrirti la massima accuratezza possibile."}/>}
                                    {sources && sources.length > 0 && loadingSources ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <FancyLoader/>
                                        </div>
                                        : null}
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </>
                : userAuth.length === 0 ? <Loader/>
                    : null}
        </div>
    );
}
import React, {useCallback, useEffect, useRef} from "react";
import {style} from "../style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faMinus} from "@fortawesome/free-solid-svg-icons";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ContentResults from "../../../discovery/results/ContentsResult/ContentResults";
import AuthorResult from "../../../discovery/results/AuthorResult/AuthorResult";
import TitlesResults from "../../../discovery/results/TitlesResults/TitlesResults";
import {ContentsResult} from "../../../discovery/type";
import TokenManager from "../../../../../common/TokenManager";
import {useNavigate} from "react-router-dom";
import Toast, {ApiError} from "../../../../../common/Toast";
import ArticleOverlay from "../../../ArticleOverlay/ArticleOverlay";
import NewPost from "../NewPost/NewPost";
import QueryFeedback from "../../../../QueryFeedback/QueryFeedback";
import {useAppSelector} from "../../../../../common/Redux/hooks";
import NoResults from "../../../../NoResults/NoResults";
import useSmartContent from "../../hooks/useSmartContent";
import useSmartAuthors from "../../hooks/useSmartAuthors";
import useSmartSources from "../../hooks/useSmartSources";
import {FilterContent} from "../../../FilterByNew/FilterByNew";
import FancyLoader from "../../../../../common/FancyLoader/FancyLoader";
import {SelectionOptionsResponse} from "../../../FilterByNew/types";
import DownloadSmartTargeting from "../../../../DownloadCSV/DownloadSmartTargeting";

type Props = {
    onClose: () => void,
    setRefresh?: () => void,
    result: PostResult,
    isNewPost?: boolean,
}

export type PostResult = {
    documentId: string,
    title: string,
    body: string,
    createdAt: string,
    lastUpdatedAt: string,
}

export type SmartTargetingFilters = {
    mediaTypes: FilterContent[],
    geoCategories: FilterContent[],
    sourceProfiles: FilterContent[],
    publicationPeriods: FilterContent
}

export default function PostResults(
    {
        onClose, result, setRefresh = () => {
    }, isNewPost = false
    }: Props
) {
    const [requestId, setRequestId] = React.useState<string>("")
    // const [authors, setAuthors] = React.useState<AuthorsResults[]>([])
    const [tabIndex, setTabIndex] = React.useState<number>(0)
    // const [titles, setTitles] = React.useState<SourcesResults[]>([])
    // const [contents, setContents] = React.useState<ContentsResult[]>([])
    const [expand, setExpand] = React.useState<boolean>(false)
    const [filtersLoading, setFiltersLoading] = React.useState<boolean>(false)
    const [filters, setFilters] = React.useState<SmartTargetingFilters | undefined>(undefined)
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState<boolean>(false)
    const [selectedContent, setSelectedContent] = React.useState<ContentsResult | undefined>(undefined)
    const [filterPhrase, setFilterPhrase] = React.useState<string>("")
    const [edit, setEdit] = React.useState<boolean>(false)
    const [confirmDelete, setConfirmDeleted] = React.useState<boolean>(false)
    const queryCount = useAppSelector((state) => state.queryCounter.value)
    const [pageContents, setPageContents] = React.useState<number>(0)
    const {
        loading: loadingContent,
        hasMore: hasMoreContents,
        contents
    } = useSmartContent(pageContents, filters, result.documentId, setRequestId, tabIndex === 0)
    const observerContents = useRef<IntersectionObserver | null>()
    const lastContent = useCallback((node: HTMLDivElement) => {
        if (loadingContent)
            return
        if (observerContents.current)
            observerContents.current.disconnect()
        observerContents.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreContents && pageContents < 5) {
                setPageContents(prevState => prevState + 1)
            }
        })
        if (node) observerContents.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingContent, hasMoreContents])
    const [pageAuthors, setPageAuthors] = React.useState<number>(0)
    const {
        loading: loadingAuthor,
        hasMore: hasMoreAuthors,
        authors
    } = useSmartAuthors(pageAuthors, filters, result.documentId, setRequestId, tabIndex === 1)
    const observerAuthors = useRef<IntersectionObserver | null>()
    const lastAuthor = useCallback((node: HTMLDivElement) => {
        if (loadingAuthor)
            return
        if (observerAuthors.current)
            observerAuthors.current.disconnect()
        observerAuthors.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreAuthors && pageAuthors < 3) {
                setPageAuthors(prevState => prevState + 1)
            }
        })
        if (node) observerAuthors.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAuthor, hasMoreAuthors])
    const [pageSources, setPageSources] = React.useState<number>(0)
    const {
        loading: loadingSource,
        hasMore: hasMoreSources,
        sources
    } = useSmartSources(pageSources, filters, result.documentId, setRequestId, tabIndex === 2)
    const observerSources = useRef<IntersectionObserver | null>()
    const lastSource = useCallback((node: HTMLDivElement) => {
        if (loadingSource)
            return
        if (observerSources.current)
            observerSources.current.disconnect()
        observerSources.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreSources && pageSources < 3) {
                setPageSources(prevState => prevState + 1)
            }
        })
        if (node) observerSources.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSource, hasMoreSources])

    const getPostFilter = async () => {
        setFiltersLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        const filtersFetch = await fetch(`${process.env.REACT_APP_BASE_URL}/setting/?tag=post-filters`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/selection-options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        if (!filtersFetch.ok || !fetched.ok) {
            let data: ApiError
            if (!filtersFetch.ok) {
                data = await filtersFetch.json()
            } else {
                data = await fetched.json()
            }
            Toast(data)
            return
        }
        const selectionOptions: SelectionOptionsResponse = await fetched.json()
        const publicationPeriods = selectionOptions.results["publicationPeriods"]
        const bigPeriod = publicationPeriods.sort((a, b) => b.id - a.id)[0]
        setFiltersLoading(false)

        let data: {
            key: "string", tag: "string", value: { documentId: string } & SmartTargetingFilters
        }[] = await filtersFetch.json()
        let newFilters: SmartTargetingFilters = {
            mediaTypes: [],
            geoCategories: [],
            sourceProfiles: [],
            publicationPeriods: bigPeriod
        }
        for (const documentFilters of data) {
            if (documentFilters.value.documentId === result.documentId) {
                newFilters = {
                    mediaTypes: documentFilters.value.mediaTypes,
                    geoCategories: documentFilters.value.geoCategories,
                    sourceProfiles: documentFilters.value.sourceProfiles,
                    publicationPeriods: documentFilters.value.publicationPeriods ? documentFilters.value.publicationPeriods : bigPeriod
                }
                break
            }
        }
        setFilters(newFilters)
    }

    const manageTabs = async () => {
        if (filters === undefined)
            await getPostFilter()
    }

    useEffect(() => {
        setPageContents(0)
        setPageAuthors(0)
        setPageSources(0)
        manageTabs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex])

    const onContentClick = (content: ContentsResult) => {
        setVisible(true)
        setSelectedContent(content)
    }

    const hideArticleOverlay = () => {
        setVisible(false)
        setSelectedContent(undefined)
    }

    useEffect(() => {
        if (filters !== undefined) {
            let newFilterPhrase = `${filters.publicationPeriods.name}, `

            for (const media of filters.mediaTypes.concat(filters.sourceProfiles)) {
                newFilterPhrase += `${media.name}, `
            }
            if (filters.geoCategories.length > 1) {
                newFilterPhrase += ` ${filters.geoCategories[0].subname} + ${filters.geoCategories.length - 1}`
            } else {
                for (const filterElement of filters.geoCategories) {
                    if (filterElement.name === "Locale")
                        newFilterPhrase += `${filterElement.subname}`
                    else
                        newFilterPhrase += `${filterElement.name}`
                }
            }
            if (newFilterPhrase.substring(newFilterPhrase.length - 2) === ", ")
                newFilterPhrase = newFilterPhrase.substring(0, newFilterPhrase.length - 2)
            setFilterPhrase(newFilterPhrase)
        } else {
            setFilterPhrase("")
        }
    }, [filters])

    const close = () => {
        setFilters(undefined)
        onClose()
    }

    const deletePost = async () => {
        if (confirmDelete) {
            const token = await TokenManager.getAccessToken()
            await fetch(`${process.env.REACT_APP_BASE_URL}/setting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    key: `post-${result.documentId}`,
                    value: null,
                    tag: "post"
                })
            })
            await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/smart-targeting/document/?documentId=${result.documentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'clientId': `0`,
                }
            })
            setRefresh()
            close()

        } else {
            setConfirmDeleted(true)
        }
    }

    return (
        <>
            {visible ? <ArticleOverlay close={hideArticleOverlay} post={selectedContent}/> : null}
            {!edit ? <>
                    <div style={style.header}>
                        {!isNewPost ? <>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <h4 style={style.title}>{"Risultati"}</h4>
                                </div>
                                <div>
                                    {/*<button style={style.button} onClick={manageTabs}>{"Aggiorna il matching"}</button>*/}
                                    <button style={confirmDelete ? style.buttonDelete : style.button}
                                            onClick={() => deletePost()}>{confirmDelete ? "Conferma eliminazione" : "Elimina"}</button>
                                    <button style={style.button} onClick={() => setEdit(true)}>{"Modifica"}</button>
                                    <button style={style.buttonYellow} onClick={close}>Chiudi</button>
                                </div>
                            </>
                            : null}
                    </div>
                    <div style={style.postTitle}>
                        <div className={"justify-content-between d-flex"}>
                            <h3 style={style.postTitleTitle}>{result.title}</h3>
                            <button style={style.expand} onClick={() => setExpand(!expand)}>
                                {"ESPANDI"}
                                <div style={style.expandIcon}>
                                    {expand ?
                                        <FontAwesomeIcon icon={faChevronDown}/>
                                        : <FontAwesomeIcon icon={faMinus}/>}
                                </div>
                            </button>
                        </div>
                        {expand ?
                            <p style={{marginTop: "16px"}}>{`${result.body.substring(0, 500)}${result.body.length > 500 ? "..." : ""}`}</p>
                            : null}
                    </div>
                    <Tabs onSelect={setTabIndex}>
                        <div className={"d-flex justify-content-between mb-3"}>
                            <TabList style={style.tabList}>
                                <Tab className={`tabListElement ${tabIndex === 0 ? "tabListElementActive" : ""}`}>
                                    {"Contenuti"}
                                </Tab>
                                <Tab className={`tabListElement ${tabIndex === 1 ? "tabListElementActive" : ""}`}>
                                    {"Autori"}
                                </Tab>
                                <Tab className={`tabListElement ${tabIndex === 2 ? "tabListElementActive" : ""}`}>
                                    {"Testate"}
                                </Tab>
                            </TabList>
                            {filters ?
                                <DownloadSmartTargeting
                                    filters={filters}
                                    documentId={result.documentId}
                                    tabIndex={tabIndex}/>
                                : null}
                        </div>
                        {filterPhrase.length > 0 ? <p>{`Risultati per: ${filterPhrase}.`}</p> : null}
                        <TabPanel>
                            {contents && contents.length > 0 ? contents.map((content, index) => {
                                if (queryCount > 0 && queryCount % 5 === 0 && index === 4)
                                    return <div key={`content-${index}`}
                                                ref={contents.length === index + 1 ? lastContent : null}>
                                        <QueryFeedback subject={"dei contenuti"} requestId={requestId}/>
                                        <ContentResults result={content} discovery={true}
                                                        onClick={() => onContentClick(content)}/>
                                    </div>
                                return <div key={`content-${index}`}
                                            ref={contents.length === index + 1 ? lastContent : null}>
                                    <ContentResults
                                        result={content} discovery={true}
                                        onClick={() => onContentClick(content)}/></div>
                            }) : loadingContent || filtersLoading ?
                                <div style={{height: "calc(100vh - 600px)", display: "flex", alignItems: "center"}}>
                                    <FancyLoader/>
                                </div>
                                : <NoResults text={"Nessun risultato  trovato"}/>}
                        </TabPanel>
                        <TabPanel>
                            {authors && authors.length > 0 ? authors.map((content, index) => {
                                if (queryCount > 0 && queryCount % 5 === 0 && index === 4)
                                    return <div key={`authors-${index}`}
                                                ref={authors.length === index + 1 ? lastAuthor : null}>
                                        <QueryFeedback subject={"dei contenuti"} requestId={requestId}/>
                                        <AuthorResult result={content}/>
                                    </div>
                                return (<div key={`authors-${index}`}
                                             ref={authors.length === index + 1 ? lastAuthor : null}>
                                    <AuthorResult result={content}/>
                                </div>)
                            }) : loadingAuthor || filtersLoading ?
                                <div style={{height: "calc(100vh - 600px)", display: "flex", alignItems: "center"}}>
                                    <FancyLoader/></div> :
                                <NoResults text={"Nessun risultato trovato"}/>}
                        </TabPanel>
                        <TabPanel>
                            {sources && sources.length > 0 ? sources.map((content, index) => {
                                if (queryCount > 0 && queryCount % 5 === 0 && index === 4)
                                    return <div key={`sources-${index}`}
                                                ref={sources.length === index + 1 ? lastSource : null}>
                                        <QueryFeedback subject={"dei contenuti"} requestId={requestId}/>
                                        <TitlesResults result={content}/>
                                    </div>
                                return (<div key={`sources-${index}`}
                                             ref={sources.length === index + 1 ? lastSource : null}>
                                    <TitlesResults result={content}/>
                                </div>)
                            }) : loadingSource || filtersLoading ?
                                <div style={{height: "calc(100vh - 600px)", display: "flex", alignItems: "center"}}>
                                    <FancyLoader/></div> :
                                <NoResults text={"Nessun risultato trovato"}/>}
                        </TabPanel>
                    </Tabs>
                </>
                : <NewPost close={close} titleEdit={result.title} bodyEdit={result.body} filtersEdit={filters}
                           documentIdEdit={result.documentId} setRefresh={() => {
                }}/>}
        </>
    );
}